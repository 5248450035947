import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';

import * as Yup from 'yup';
import { defaultBorrowerLegalName } from '../../../../../../../components/loan_application/form_models/constants';
import { NON_PROFIT_ORGANIZATION_TYPES } from '../../../../../../../constants';
const {
  formField: { borrowerReligiousLeaderYears, nonProfitOrganizationType },
} = loanFormModel;

export class BorrowerReligiousLeaderYearsValidation
  implements ValidationSchema
{
  public name: string = borrowerReligiousLeaderYears.name;
  public label = borrowerReligiousLeaderYears.getLabel(
    defaultBorrowerLegalName
  );
  public dependsOn: string[] = [nonProfitOrganizationType.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(nonProfitOrganizationType: string, schema: Yup.StringSchema) {
    if (nonProfitOrganizationType === NON_PROFIT_ORGANIZATION_TYPES.FBO)
      return schema.required('This field is required');
    return schema;
  }
}
export default new Validation(new BorrowerReligiousLeaderYearsValidation());
