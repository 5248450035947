import { Contact } from '../../../../../../../types/api';
import { APPLICATION_FLOW_TYPE } from './constants';
import {
  applicationSteps,
  applicationStepsByIDs,
} from './MainFlow/ApplicationStepsConfig';

import * as NewWelcomeFlowConfig from './NewWelcomeFlow/ApplicationStepsConfig';

export const getApplicationSteps = (contact: Contact | undefined): any => {
  const flags = contact?.feature_flags?.split(';') || [];
  if (flags.indexOf('Welcome Page V2') !== -1)
    return {
      applicationSteps: NewWelcomeFlowConfig.applicationSteps,
      applicationStepsByIDs: NewWelcomeFlowConfig.applicationStepsByIDs,
    };

  return { applicationSteps, applicationStepsByIDs };
};

export const getCurrentFlow = (contact: Contact): APPLICATION_FLOW_TYPE => {
  const flags = contact?.feature_flags?.split(';') || [];
  if (flags.indexOf('Welcome Page V2') !== -1)
    return APPLICATION_FLOW_TYPE.NEW_WELCOME_PAGE;
  return APPLICATION_FLOW_TYPE.MAIN;
};

export {};
