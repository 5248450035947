import * as Yup from 'yup';
import {
  PropertyAddressValidation,
  PropertyAddressCityValidation,
  PropertyAddressStateValidation,
  PropertyAddressZipCodeValidation,
  PropertyAppraisedValueValidation,
  PropertyOwnershipStatusValidation,
  PropertyLegalNameOfOwnerValidation,
  PropertyOwnerAffiliatedWithBorrowerValidation,
  PropertyLeaseExpirationYearValidation,
  PropertyCurrentlyPayingMortgageValidation,
} from './validations/solar_system_property';

import {
  OutstandingLoanValidation,
  PropertyLandlordConsentValidation,
  PropertyAddressIsSameAsBusinessValidation,
} from '../../../../../validations/solar_system_property';

import {
  SingleOrMultipleUsersValidation,
  CommunityOrPPALeaseValidation,
  LegalNamesOfOfftakersValidation,
  NumberOfOfftakersValidation,
  PowerTaxEquityPartneringValidation,
  TaxEquityFundingPercentageValidation,
  OfftakerNeedsPercentageValidation,
  OfftakerUtilityRateDiscountValidation,
  SubscribersAmountValidation,
  SubscribersDiscountValidation,
  UtilityJurisdictionNameValidation,
  SubscriberManagementCompanyValidation,
  NonProfitRecievedBoardApprovalValidation,
} from '../../../../../validations/solar_system_power';
import {
  BusinessAddressValidation,
  BusinessAddressCityValidation,
  BusinessAddressStateValidation,
  BusinessAddressZipCodeValidation,
  MailingAddressSameAsBusinessPicklistValidation,
  MailingAddressValidation,
  MailingAddressCityValidation,
  MailingAddressStateValidation,
  MailingAddressZipCodeValidation,
} from '../../../../../validations/borrower_information';

import {
  BorrowerBusinessStructureValidation,
  BorrowerTinValidation,
  BorrowerGrossRevenueLatestValidation,
  BorrowerBusinessEstablishedYearValidation,
  BorrowerYearsManagementInPlaceValidation,
  BorrowerManagementExperienceYearsValidation,
  BorrowerLinesOfBusinessValidation,
} from '../../../../../validations/ownership_history';

import {
  BorrowerReligiousParentOrgSupportValidation,
  BorrowerReligiousLeaderYearsValidation,
} from './validations/business_information';

// Solar System Property Usage

import { SystemPowerUsageValidation } from './validations/solar_system_power';

export const solarSystemPropertyUsage = [
  // Solar System Location
  PropertyAddressValidation,
  PropertyAddressCityValidation,
  PropertyAddressStateValidation,
  PropertyAddressZipCodeValidation,

  // Solar System Property
  PropertyAppraisedValueValidation,
  PropertyOwnershipStatusValidation,
  PropertyLegalNameOfOwnerValidation,
  PropertyOwnerAffiliatedWithBorrowerValidation,
  PropertyLandlordConsentValidation,
  PropertyLeaseExpirationYearValidation,
  PropertyCurrentlyPayingMortgageValidation,
  OutstandingLoanValidation,

  // PPA / Community Solar
  SystemPowerUsageValidation,
  SingleOrMultipleUsersValidation,
  CommunityOrPPALeaseValidation,
  LegalNamesOfOfftakersValidation,
  NumberOfOfftakersValidation,
  PowerTaxEquityPartneringValidation,
  TaxEquityFundingPercentageValidation,
  OfftakerNeedsPercentageValidation,
  OfftakerUtilityRateDiscountValidation,
  SubscribersAmountValidation,
  SubscribersDiscountValidation,
  UtilityJurisdictionNameValidation,
  SubscriberManagementCompanyValidation,
].reduce((acc, validation) => {
  acc[validation.name] = validation.buildSchema();
  return acc;
}, {});

export const solarSystemPropertyUsageSchema = Yup.object().shape(
  solarSystemPropertyUsage
);

// Business Information
export const businessInformation = [
  // Business
  PropertyAddressIsSameAsBusinessValidation,
  BusinessAddressValidation,
  BusinessAddressCityValidation,
  BusinessAddressStateValidation,
  BusinessAddressZipCodeValidation,
  // Mailing
  MailingAddressSameAsBusinessPicklistValidation,
  MailingAddressValidation,
  MailingAddressCityValidation,
  MailingAddressStateValidation,
  MailingAddressZipCodeValidation,

  // Business Details
  BorrowerBusinessStructureValidation,
  BorrowerTinValidation,
  BorrowerGrossRevenueLatestValidation,
  BorrowerBusinessEstablishedYearValidation,
  BorrowerYearsManagementInPlaceValidation,
  BorrowerManagementExperienceYearsValidation,
  BorrowerLinesOfBusinessValidation,
  NonProfitRecievedBoardApprovalValidation,
  BorrowerReligiousParentOrgSupportValidation,
  BorrowerReligiousLeaderYearsValidation,
].reduce((acc, validation) => {
  acc[validation.name] = validation.buildSchema();
  return acc;
}, {});

export const businessInformationSchema =
  Yup.object().shape(businessInformation);
