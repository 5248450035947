import { LoanCategory } from '../../../../types/api';
import loanFormModel from '../components/loan_application/form_models/loanFormModel';
import { BusinessRuleBase } from './BusinessBase';
import { ApplicationFormValues } from '../pages/dashboard/loan_detail/LoanDetailTypes';

const {
  plaidAssetReport,
  bankStatementLastMonth,
  bankStatement2Month,
  bankStatement3Month,
} = loanFormModel.formField.documents;

export class BankStatementsBL extends BusinessRuleBase {
  constructor(data: Partial<ApplicationFormValues>) {
    super(data);
  }

  // In case the plaid service is down, we don't want to show the plaid link
  public isPlaidServiceUp(): boolean {
    return (
      this.data[plaidAssetReport.name] &&
      Object.keys(this.data[plaidAssetReport.name]).length > 0
    );
  }

  public isReportUploaded(): boolean {
    return this.data[plaidAssetReport.name]?.asset_report_status === 'SUCCESS';
  }

  // calculate previous month and/or year for bank statements
  public getMonthAndYearForStatementType(statementType: string) {
    const applicationStartDate = this.data['application_start_date'];
    let month: number;
    let year: number;
    if (applicationStartDate) {
      const [yearStr, monthStr, _dayStr] = applicationStartDate.split('-');
      month = parseInt(monthStr);
      year = parseInt(yearStr);
    } else {
      // if no application start date, use today's date
      const today = new Date();
      year = today.getFullYear();
      month = today.getMonth() + 1;
    }
    const monthsToGoBack =
      statementType === bankStatementLastMonth.name
        ? 1
        : statementType === bankStatement2Month.name
        ? 2
        : 3;
    if (monthsToGoBack > month) {
      year -= 1;
      month = 12 - (monthsToGoBack - month);
    } else {
      month -= monthsToGoBack;
    }
    return [year.toString(), month.toString()];
  }

  public getBankStatementFields() {
    const isExpressPlusLoan = [
      LoanCategory.EXPRESS_PLUS_OPCO,
      LoanCategory.EXPRESS_PLUS_PROPCO,
    ].includes(this.data.loan_category as LoanCategory);
    const statements: Record<string, any> = {
      bankStatementLastMonth,
      bankStatement2Month,
    };
    if (!isExpressPlusLoan)
      statements.bankStatement3Month = bankStatement3Month;
    return statements;
  }

  public hasAtLeastOneBankStatementUploaded(): boolean {
    return Object.values(this.getBankStatementFields()).some(
      (field) => !!this.data[field.name]
    );
  }

  public isBankStatementRequired(): boolean {
    if (
      this.isAssetReportSuccessful() ||
      this.isSoldToAnotherEntity() ||
      (this.isPersonalGuarantor() && this.getAverageCashBalance() > 0)
    )
      return false;
    return true;
  }

  public getRules() {
    const rules = {
      powerSoldToAnotherEntity: this.isSoldToAnotherEntity(),
      plaidServiceUp: this.isPlaidServiceUp(),
      assetReportIsUploaded: this.isReportUploaded(),
      bankStatementFields: this.getBankStatementFields(),
      atLeastOneBankStatementUploaded:
        this.hasAtLeastOneBankStatementUploaded(),
    };
    return rules;
  }
}
