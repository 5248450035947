import * as Yup from 'yup';
import {
  welcomePage,
  welcomePageSchema,
  businessIdentification,
  businessIdentificationSchema,
  ownershipHistory,
  ownershipHistorySchema,
  principals,
  principalsSchema,
  solarSystemProperty,
  solarSystemPropertySchema,
  systemPower,
  systemPowerSchema,
} from '../../../../../components/loan_application/form_models/stage1ValidationSchema';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {
  consentValidation,
  consentValidationSchema,
  documentsValidation,
  documentsValidationSchema,
  outstandingLoans,
  outstandingLoansSchema,
} from '../../../../../components/loan_application/form_models/stage2ValidationSchema';
import { ApplicationGroups, ApplicationStepID } from '../../LoanDetailTypes';
import { FolderCheckMark } from '../CustomIcons';
import {
  solarSystemPropertyUsage,
  solarSystemPropertyUsageSchema,
  businessInformation,
  businessInformationSchema,
} from './validations';

export type ApplicationStep = {
  id: ApplicationStepID;
  title: string;
  dependsOn?: ApplicationStepID[];
  validationSchema?: Yup.ObjectSchema<any>;
  fields?: Record<string, any>;
  group: ApplicationGroups;
  index: number;
  icon?: React.ReactNode;
};

const reviewValidationSchema = welcomePageSchema
  .concat(businessIdentificationSchema)
  .concat(solarSystemPropertySchema)
  .concat(ownershipHistorySchema)
  .concat(principalsSchema)
  .concat(documentsValidationSchema)
  .concat(outstandingLoansSchema)
  .concat(systemPowerSchema);

const reviewFields = {
  ...welcomePage,
  ...businessIdentification,
  ...solarSystemProperty,
  ...ownershipHistory,
  ...principals,
  ...documentsValidation,
  ...outstandingLoans,
  ...systemPower,
};

export const fullValidationSchema = reviewValidationSchema.concat(
  consentValidationSchema
);

export const applicationSteps: ApplicationStep[] = [
  {
    id: ApplicationStepID.SOLAR_SYSTEM_PROPERTY_USAGE,
    title: 'Solar System Property & Usage',
    validationSchema: solarSystemPropertyUsageSchema,
    fields: solarSystemPropertyUsage,
    group: ApplicationGroups.APP_INFO,
  },
  {
    id: ApplicationStepID.BUSINESS_INFORMATION,
    title: 'Business Information',
    validationSchema: businessInformationSchema,
    fields: businessInformation,
    group: ApplicationGroups.APP_INFO,
  },
  {
    id: ApplicationStepID.LOAN_OBLIGATIONS,
    title: 'Loan Obligations',
    fields: outstandingLoans,
    validationSchema: outstandingLoansSchema,
    group: ApplicationGroups.APP_INFO,
  },
  {
    id: ApplicationStepID.PRINCIPALS,
    title: 'Principals Information',
    validationSchema: principalsSchema,
    fields: principals,
    group: ApplicationGroups.APP_INFO,
  },
  {
    id: ApplicationStepID.REQUIRED_DOCUMENTS,
    title: 'Required Documents',
    validationSchema: documentsValidationSchema,
    fields: documentsValidation,
    group: ApplicationGroups.APP_INFO,
  },

  {
    id: ApplicationStepID.REVIEW,
    title: 'Review',
    validationSchema: reviewValidationSchema,
    fields: reviewFields,
    dependsOn: [
      ApplicationStepID.SOLAR_SYSTEM_PROPERTY_USAGE,
      ApplicationStepID.BUSINESS_INFORMATION,
      ApplicationStepID.LOAN_OBLIGATIONS,
      ApplicationStepID.PRINCIPALS,
      ApplicationStepID.REQUIRED_DOCUMENTS,
    ] as ApplicationStepID[],
    group: ApplicationGroups.APP_INFO,
    icon: <FolderCheckMark />,
  },
  {
    id: ApplicationStepID.CONSENT,
    title: 'Sign and Consent',
    validationSchema: businessIdentificationSchema
      .concat(solarSystemPropertySchema)
      .concat(ownershipHistorySchema)
      .concat(principalsSchema)
      .concat(documentsValidationSchema)
      .concat(outstandingLoansSchema)
      .concat(systemPowerSchema)
      .concat(consentValidationSchema),
    fields: {
      ...businessIdentification,
      ...solarSystemProperty,
      ...ownershipHistory,
      ...principals,
      ...documentsValidation,
      ...outstandingLoans,
      ...systemPower,
      ...consentValidation,
    },
    dependsOn: [ApplicationStepID.REVIEW] as ApplicationStepID[],
    group: ApplicationGroups.APP_INFO,
    icon: <BorderColorIcon />,
  },
].map((step, index) => ({ ...step, index }));

export const applicationStepsByIDs = applicationSteps.reduce(
  (acc, step) => ({ ...acc, [step.id]: step }),
  {} as {
    [key in ApplicationStepID]: ApplicationStep;
  }
);

export const applicationInfoSteps = applicationSteps.filter(
  (step) => step.group === ApplicationGroups.APP_INFO
);
