import { NumberFormatValues } from 'react-number-format';
import {
  LIMITS,
  COMPANY_TYPES,
  SYSTEM_POWER_USAGE_VALUES,
  FOR_PROFIT_COMPANY_TYPES,
  BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT,
  INDUSTRY_BORROWER_REPORTED_TYPES,
  NON_PROFIT_ORGANIZATION_TYPES,
  PG_FOR_PROFIT_COMPANY_TYPES,
} from '../../../constants';
import {
  Application,
  LoanCategory,
  ProductFamily,
} from '../../../../../../types/api';
import { zonedTimeToUtc } from 'date-fns-tz';
import { api } from '../../../services/apis';
import { ApplicationFormValues } from '../../../pages/dashboard/loan_detail/LoanDetailTypes';

const forProfitCompanyIsPGOrEmpty = (forProfitCompanyType) => {
  return (
    PG_FOR_PROFIT_COMPANY_TYPES.includes(forProfitCompanyType) ||
    forProfitCompanyType === ''
  );
};

// this determines if a loan requires a personal guarantor
export const updateIsPersonalGuarantor = (control, setValue) => {
  const fv = control._formValues;
  const loanSizeCriteria =
    fv.loan_amount && fv.loan_amount <= LIMITS.PG_LOAN_AMOUNT_CEILING;
  const forProfitCompanyType = fv.for_profit_company_type;

  // Operating Company, Property Company or Agriculture
  if (loanSizeCriteria && forProfitCompanyIsPGOrEmpty(forProfitCompanyType)) {
    fv.is_personal_guarantor = 'Yes';
  } else {
    fv.is_personal_guarantor = 'No';
  }
  setValue('is_personal_guarantor', fv.is_personal_guarantor);
  return fv.is_personal_guarantor;
};

export const determineArchetype = (fv: ApplicationFormValues) => {
  const isExpressLoanAmount =
    fv.loan_amount && fv.loan_amount <= LIMITS.PG_LOAN_AMOUNT_CEILING;

  const isExpressPlusLoanAmount =
    fv.loan_amount &&
    fv.loan_amount > LIMITS.EXPRESS_PLUS_LOAN_AMOUNT_FLOOR &&
    fv.loan_amount <= LIMITS.EXPRESS_PLUS_LOAN_AMOUNT_CEILING;

  const isPPALoanAmount =
    fv.loan_amount && fv.loan_amount > LIMITS.PPA_LOAN_AMOUNT_FLOOR;

  // For-Profit Archetypes
  switch (fv.business_type_for_profit_or_non_profit) {
    case BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT.FOR_PROFIT:
      // OpCo Archetypes
      if (
        fv.for_profit_company_type ===
        FOR_PROFIT_COMPANY_TYPES.SELLS_GOODS_OR_SERVICES
      ) {
        fv.solar_system_power_company_type = COMPANY_TYPES.OPERATING_COMPANY;
        // Express OpCo
        if (isExpressLoanAmount) {
          return LoanCategory.EXPRESS_OPCO;
          // Express Plus OpCo
        } else if (isExpressPlusLoanAmount) {
          return LoanCategory.EXPRESS_PLUS_OPCO;
          // Core OpCo
        } else {
          return LoanCategory.CORE_OPCO;
        }

        // PropCo Archetypes
      } else if (
        fv.for_profit_company_type ===
        FOR_PROFIT_COMPANY_TYPES.COLLECTS_RENT_FROM_TENANTS
      ) {
        fv.solar_system_power_company_type = COMPANY_TYPES.PROPERTY_COMPANY;
        // Express PropCo
        if (isExpressLoanAmount) {
          return LoanCategory.EXPRESS_PROPCO;
          // Express Plus PropCo
        } else if (isExpressPlusLoanAmount) {
          return LoanCategory.EXPRESS_PLUS_PROPCO;
          // Core PropCo
        } else {
          return LoanCategory.CORE_PROPCO;
        }

        // Agriculture Archetypes
      } else if (
        fv.industry_borrower_reported ===
        INDUSTRY_BORROWER_REPORTED_TYPES.AGRICULTURE
      ) {
        fv.solar_system_power_company_type = COMPANY_TYPES.AGRICULTURE;
        // Agriculture OpCo
        if (
          (fv.for_profit_company_type as unknown as FOR_PROFIT_COMPANY_TYPES) ===
            FOR_PROFIT_COMPANY_TYPES.SELLS_GOODS_OR_SERVICES &&
          fv.loan_amount &&
          fv.loan_amount > LIMITS.AGRICULTURE_LOAN_AMOUNT_FLOOR
        ) {
          return LoanCategory.INDUSTRY_AGRICULTURE_OPCO;
          // Agriculture PropCo
        } else if (
          (fv.for_profit_company_type as unknown as FOR_PROFIT_COMPANY_TYPES) ===
            FOR_PROFIT_COMPANY_TYPES.COLLECTS_RENT_FROM_TENANTS &&
          fv.loan_amount &&
          fv.loan_amount > LIMITS.AGRICULTURE_LOAN_AMOUNT_FLOOR
        ) {
          return LoanCategory.INDUSTRY_AGRICULTURE_PROPCO;
        }
      }
      // PPA Finance Archetypes
      else if (
        isPPALoanAmount &&
        (fv.for_profit_company_type as unknown as FOR_PROFIT_COMPANY_TYPES) ===
          FOR_PROFIT_COMPANY_TYPES.SELLS_SOLAR_GENERATED_POWER_TO_THIRD_PARTIES
      ) {
        // Has no original equivalent Company Type
        return LoanCategory.PPA_FINANCE;
      }

      break;

    // Non-Profit Archetypes
    case BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT.NON_PROFIT:
      if (
        fv.non_profit_organization_type === NON_PROFIT_ORGANIZATION_TYPES.FBO
      ) {
        fv.solar_system_power_company_type = COMPANY_TYPES.NON_PROFIT;
        return LoanCategory.NPO_FBO;
      } else if (
        fv.non_profit_organization_type === NON_PROFIT_ORGANIZATION_TYPES.HOA
      ) {
        fv.solar_system_power_company_type = COMPANY_TYPES.HOA;
        return LoanCategory.NPO_HOA;
      } else if (
        fv.non_profit_organization_type === NON_PROFIT_ORGANIZATION_TYPES.EDU
      ) {
        fv.solar_system_power_company_type = COMPANY_TYPES.NON_PROFIT;
        return LoanCategory.NPO_EDUCATIONAL_INSTITUTE;
      } else {
        fv.solar_system_power_company_type = COMPANY_TYPES.NON_PROFIT;
        return LoanCategory.NPO_OTHER;
      }
  }
};

export const updateLoanCategory = (control) => {
  const fv = control._formValues;
  fv.loan_category = determineArchetype(fv);
};

const isPGRequired = (
  loanCategory: LoanCategory,
  annualGrossIncome: number,
  yearsAgoEstablished: number
) => {
  /**
   * This function aims to decouple the logic for when a Loan App requires a PG ('Is [PRINCIPAL] willing to be a Personal Guarantor?'),
   * from the 'requiresPGPercentOwnership()' function.
   * allowing it to scale easier and keep adding rules as the business logic changes.
   * This is not related to the principalIsPG field validation, but to the section completion,
   * determining when it can be completed without providing a PG
   */
  if (
    [LoanCategory.EXPRESS_OPCO, LoanCategory.EXPRESS_PROPCO].includes(
      loanCategory
    )
  ) {
    // Express OpCo, Express PropCo, Industry Agriculture OpCo, Industry Agriculture PropCo require a PG unless they meet the annual gross income and years established criteria
    return !(
      annualGrossIncome > LIMITS.EXPRESS_REVENUE_CEILING &&
      yearsAgoEstablished > LIMITS.YEARS_AGO_ESTABLISHED_FLOOR
    );
  } else if ([LoanCategory.EXPRESS_PLUS_OPCO].includes(loanCategory)) {
    // Express Plus OpCo and Express Plus PropCo require a PG unless they meet the annual gross income and years established criteria
    if (
      annualGrossIncome > LIMITS.EXPRESS_PLUS_REVENUE_CEILING &&
      yearsAgoEstablished > LIMITS.YEARS_AGO_ESTABLISHED_FLOOR
    ) {
      return false;
    }
    return true;
    // Always require a PG for Industry Archetypes and Express Plus PropCo
  } else if (
    [
      LoanCategory.INDUSTRY_AGRICULTURE_PROPCO,
      LoanCategory.INDUSTRY_AGRICULTURE_OPCO,
      LoanCategory.EXPRESS_PLUS_PROPCO,
    ].includes(loanCategory)
  ) {
    return true;
  } else {
    // All the other Archetypes do not require a PG
    return false;
  }
};

export const calculatePGPercentOwnership = (principals): number => {
  // only relevant for PG loans
  let pgPercentOwnership = 0;
  for (const principal of principals) {
    // only relevant if principal is a PG
    if (
      principal.principal_is_personal_guarantor === 'Yes' &&
      principal.percent_ownership
    ) {
      pgPercentOwnership += parseFloat(principal.percent_ownership);
    }
  }
  return parseFloat(pgPercentOwnership.toFixed(2));
};

export const countPGs = (principals): number => {
  return principals.filter(
    (principal) => principal.principal_is_personal_guarantor === 'Yes'
  ).length;
};

export const isValidSFCurrency = (values: NumberFormatValues): boolean => {
  const { floatValue } = values;
  if (floatValue) return floatValue >= 0 && floatValue <= 10000000000000000;
  return true;
};

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

export const shortenFilename = (name: string, maxLength: number) => {
  if (name.length <= maxLength) {
    return name;
  }
  return name.substring(0, maxLength - 3) + '...';
};

export const requiresPGPercentOwnership = (application: Application) => {
  const isTenant =
    application.solar_system_power_usage ===
    SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY;
  if (isTenant) return false;
  const borrowerGrossRevenueLatest = application.borrower_gross_revenue_latest;
  const borrowerBusinessEstablishedYear = application.business_established_year;
  const currentYear = new Date().getFullYear();

  const yearsAgoEstablished =
    borrowerBusinessEstablishedYear &&
    currentYear - borrowerBusinessEstablishedYear;

  const loanCategory = application.loan_category;

  if (!loanCategory || !borrowerGrossRevenueLatest || !yearsAgoEstablished) {
    return true;
  }

  return isPGRequired(
    loanCategory,
    borrowerGrossRevenueLatest,
    yearsAgoEstablished
  );
};

export const missingApplicantSameAsPrincipal = (application: Application) => {
  /* This utility function is to be used in ApplicationStepsContext and ApplicationPage, since it overwrites the section
  validation, to make the section incomplete if missing the field Applicant same as Principal 
  -which is already required in Yup validation- */
  if (!application.principals || application.principals.length === 0) {
    return false;
  }

  const hasApplicantSameAsPrincipal = application.principals.some(
    (principal) => principal.applicant_same_as_principal === 'Yes'
  );

  // We need to check:
  /**
   - Pricipal selected Yes to is PG
   - Didn't select any field for ASAP
   - AND there's no other Principal that already selected 'Yes' to ASAP (if so, that question will never show and will always be null)
   */
  const missingAsap = application.principals.some(
    (principal) =>
      principal.principal_is_personal_guarantor === 'Yes' &&
      principal.applicant_same_as_principal === null &&
      !hasApplicantSameAsPrincipal
  );

  return missingAsap;
};

export const isYes = (value: 'Yes' | 'No' | null): boolean => value === 'Yes';
export const isNo = (value: 'Yes' | 'No' | null): boolean => value === 'No';
export const isCurrentDateBetween = (startDate, endDate) => {
  const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDateUTC = zonedTimeToUtc(new Date(), currentTz);

  const referenceTz = 'America/New_York';
  const startDateUTC = zonedTimeToUtc(startDate, referenceTz);
  const endDateUTC = zonedTimeToUtc(endDate, referenceTz);
  return currentDateUTC >= startDateUTC && currentDateUTC <= endDateUTC;
};

interface SelectedProduct {
  rate: number;
  amortizationYears: number;
  loanTermYears: number;
  productFamily: ProductFamily;
}

interface SendMonthlyPaymentRequestParams {
  selectedProduct: SelectedProduct;
  loanAmount: number;
  specialPaymentPercentage?: number;
}

export const sendMonthlyPaymentRequest = async ({
  selectedProduct,
  loanAmount,
  specialPaymentPercentage,
}: SendMonthlyPaymentRequestParams) => {
  // Send a request to Railway endpoint to calculate the monthly payment

  const { rate, amortizationYears, loanTermYears, productFamily } =
    selectedProduct;

  let prodFamilyShort: string;

  switch (productFamily) {
    case ProductFamily.STANDARD:
      prodFamilyShort = 'standard';
      break;
    case ProductFamily.SPECIAL_PAYMENT:
      prodFamilyShort = 'special';
      break;
    default:
      prodFamilyShort = '';
  }

  const params = {
    loanAmount: loanAmount.toString(),
    annualRate: rate.toString(),
    numberOfYears: loanTermYears.toString(),
    type: prodFamilyShort,
    amortizationYears: amortizationYears.toString(),
    ...(specialPaymentPercentage
      ? { specialPaymentPercentage: specialPaymentPercentage.toString() }
      : {}),
  };

  const queryString = new URLSearchParams(params).toString();
  const response = await api().get(`calculate-monthly-payment?${queryString}`);

  const data = JSON.parse(response.data.join(''));

  return data.monthlyPayment;
};

/**
 * Returns array of the last four years including the current year
 */
export const getTaxYears = (): number[] => {
  const currentYear = new Date().getFullYear();
  return [currentYear, currentYear - 1, currentYear - 2, currentYear - 3];
};

export const getTaxYearsStr = (): string[] => {
  return getTaxYears().map((year) => year.toString());
};

// adjusting for tax season in april.  from Jan - April, this will be last year, after April will be the current year
export const getPG1Year = (): number => {
  const currentYear = new Date().getFullYear();
  return currentYear - (new Date().getMonth() + 1 < 5 ? 2 : 1);
};
