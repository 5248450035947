import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
const {
  formField: { systemPowerUsage, forProfitCompanyType },
} = loanFormModel;

export class SystemPowerUsageValidation implements ValidationSchema {
  public name: string = systemPowerUsage.name;
  public label: string = systemPowerUsage.label;
  public dependsOn: string[] = [forProfitCompanyType.name];
  public type = 'string';
  public required = {
    message: 'This field is required',
    value: systemPowerUsage.required,
  };
  public nullable = true;

  public when = (forProfitCompanyTypeValue: string, schema) => {
    if (forProfitCompanyTypeValue === 'Sells goods or services') return schema;
    return schema.notRequired();
  };
}
export default new Validation(new SystemPowerUsageValidation());
